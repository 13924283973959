import { Fragment, useEffect } from "react";
import { Form, Col, Row, OverlayTrigger, Popover } from "react-bootstrap";
import Remitente from "components/Orders/Clientes/Remitente";
import { validarRut } from "utils/rut";
import ModalMessage from "./ModalMessage";
import PanelDetalle from "components/Layouts/PanelDetalle";
import ModalSelectNroCuenta from "components/Orders/Clientes/ModalSelectNroCuenta";

import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
import {
  handlePasteOnlyLetter,
  validateOnlyLetter,
  validateOnlyNumber,
  validateOnlyRUT,
  handlePasteOnlyNumber,
} from "utils/validationInput";
import { useCustomerOrder } from "hooks/Orders/useCustomerOrder";

const Principal = ({
  formElement,
  stateOrden,
  companyDataBasic,
  editOrden,
}) => {
  const { register, errors, getValues, setValue, control } = formElement;
  const { dataTransportOrder } = stateOrden;
  const { state, actions } = useCustomerOrder();
  const { codigo, hidden, habCliente, typeCustomer, customer, openCloseCta } =
    state;
  const {
    onCustomerBlur,
    setDataCodigo,
    activeInputAccountNumber,
    changeValueCI,
    onKeyDownHandlerInput,
    onKeyDownHandlerAccountNummber,
    setHidden,
    cleanInput,
    setHabCliente,
    setHabDestination,
    setHabRemitente,
    handleBlurCliente,
    changeTypeCliente,
    handleBlurRut,
    dataTypeCliente,
    setCodigo,
    handleOpenCloseCta,
  } = actions;

  const handleCloseModal = () => {
    setHidden(false);

    if (typeCustomer === "principal") {
      const rut = getValues("cliente_rut");
      cleanInput(setValue, null, "input", "");
      setValue("cliente_rut", rut);
      setHabCliente(false);
    } else if (typeCustomer === "remitente") {
      const rut = getValues("rut_remitente");
      setValue("cliente_rut", rut);
      setValue("razon_social_remitente", "");
      setHabRemitente(false);
    } else if (typeCustomer === "destinatario") {
      const rut = getValues("rut_destinatario");
      setValue("rut_destinatario", rut);
      setValue("razon_social_destinatario", "");
      setValue("direccion_destinatario", "");
      setValue("telefono_avisas", "");
      setHabDestination(false);
    }
  };

  const selectCodeClient = (codigo) => {
    setValue("razon_social", customer?.full_name);
    setValue("cliente_rut", customer?.rut);
    const nameTypeCustomer = document.getElementById("tipo_cliente_remitente")
      .checked
      ? "tipo_cliente_remitente"
      : "tipo_cliente_destinatario";

    dataTypeCliente(
      document.getElementById(nameTypeCustomer).value,
      customer?.address,
      setValue
    );
    //setValue("telefono_avisas", getPhone(clienteSelected.telefono));
    setValue("cliente_existe", "creado");
    setHabCliente(true);
    setDataCodigo(codigo);
    setValue("codigo_interno", "si");
    setCodigo(true);
    setValue("codigo_interno_nro", codigo);
    ////////////////setDataCodigo(codigo);
    //dispatch(clienteCtaCorriente(true))

    handleOpenCloseCta();
  };

  useEffect(() => {
    if (editOrden) {
      if (
        (dataTransportOrder?.codigo_interno_nro !== null) &
        (dataTransportOrder?.codigo_interno_nro !== "")
      ) {
        setCodigo(true);
      } else {
        setCodigo(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrden, dataTransportOrder?.codigo_interno_nro]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        Presione <strong>enter</strong> para realizar la búsqueda
      </Popover.Content>
    </Popover>
  );

  return (
    <Fragment>
      <PanelDetalle title="CLIENTE" subtitle="" check={false}>
        <Form.Row className="pt-1 mb-0">
          <Col xs={5} className="mb-0">
            <Form.Group as={Col} className="mb-0">
              <p className="fw-600 font-s-10 mb-0">
                SELECCIONA EL CLIENTE QUE VAS INGRESAR
              </p>
            </Form.Group>
          </Col>
          <Col className="mb-0">
            <Form.Group as={Col} className="mb-0">
              <div>
                <Form.Check
                  onChange={(event) =>
                    activeInputAccountNumber(event, setValue)
                  }
                  inline
                  label="Cliente Creado"
                  type="radio"
                  value="creado"
                  id="cliente_creado"
                  name="cliente_existe"
                  ref={register}
                  defaultChecked
                />
                <Form.Check
                  onChange={(event) =>
                    activeInputAccountNumber(event, setValue)
                  }
                  inline
                  label="Cliente Nuevo"
                  type="radio"
                  value="nuevo"
                  id="cliente_nuevo"
                  name="cliente_existe"
                  ref={register}
                />
              </div>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group as={Col} className="mb-1">
              <hr className="line-division" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row className="pb-1 mb-0">
          <Col lg md={4} className="mb-0">
            <Form.Group as={Col} className="mb-0">
              <Form.Label>Cod. Interno</Form.Label>
              <div className="mb-2">
                <Form.Check
                  onChange={(event) => changeValueCI(event, setValue)}
                  inline
                  label="Si"
                  type="radio"
                  value="si"
                  id="codigo_intern_si"
                  name="codigo_interno"
                  ref={register}
                />
                <Form.Check
                  onChange={(event) => changeValueCI(event, setValue)}
                  inline
                  label="No"
                  type="radio"
                  value="no"
                  id="codigo_intern_no"
                  name="codigo_interno"
                  ref={register}
                />
              </div>
              <OverlayTrigger placement="right" overlay={popover}>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  //defaultValue={ordenInfo.codigo_interno_nro}
                  name="codigo_interno_nro"
                  id="codigo_interno_nro"
                  placeholder="Ingresa código"
                  ref={register}
                  readOnly={!codigo}
                  onChange={(event) => setDataCodigo(event.target.value)}
                  onKeyDown={(event) =>
                    onKeyDownHandlerAccountNummber(event, setValue, getValues)
                  }
                  onBlur={(event) => onCustomerBlur(event, setValue, getValues)}
                  maxLength={4}
                  onPaste={(event) => handlePasteOnlyNumber(event)}
                  onKeyPress={(event) => validateOnlyNumber(event)}
                />
              </OverlayTrigger>
            </Form.Group>
          </Col>
          <Col md={6} xs={12} className="mb-0">
            <Form.Group controlId="cliente_rut" className="mb-0">
              <Form.Label className="pl-3">RUT</Form.Label>
              <Col sm={6}>
                <OverlayTrigger placement="right" overlay={popover}>
                  <Controller
                    autoComplete="off"
                    render={(props) => (
                      <MaskedInput
                        mask={RutTextMask}
                        className="form-control"
                        id="cliente_rut"
                        name="cliente_rut"
                        value={props.value}
                        maxLength={12}
                        //defaultValue={ordenInfo.cliente_rut}
                        onChange={(value) => {
                          props.onChange(value);
                        }}
                        onBlur={(event) =>
                          handleBlurRut(event.target.value, setValue, getValues)
                        }
                        onKeyDown={(event) =>
                          onKeyDownHandlerInput(event, setValue)
                        }
                        onKeyPress={(event) => validateOnlyRUT(event)}
                      />
                    )}
                    control={control}
                    name="cliente_rut"
                    rules={{ required: { value: true, message: "Requerido" } }}
                  />
                </OverlayTrigger>
                {errors.cliente_rut && (
                  <Form.Text className="text-danger">
                    {errors?.cliente_rut?.message}
                  </Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Col} controlId="razon_social" className="mb-0">
              <Form.Label>Razón Social</Form.Label>
              <Form.Control
                type="text"
                name="razon_social"
                className="text-uppercase"
                //defaultValue={ordenInfo.razon_social}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                readOnly={habCliente}
                onBlur={(event) =>
                  handleBlurCliente(event, setValue, getValues)
                }
                onKeyPress={(event) => validateOnlyLetter(event)}
                maxLength={150}
                onPaste={(event) => handlePasteOnlyLetter(event)}
              />
              {errors.razon_social && (
                <Form.Text className="text-danger">
                  {errors?.razon_social?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="tipo_cliente_remitente">
              <Form.Label>Tipo de cliente</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  id="tipo_cliente_remitente"
                  label="Remitente"
                  name="tipo_cliente"
                  value="remitente"
                  ref={register}
                  onChange={(event) =>
                    changeTypeCliente(event, setValue, getValues)
                  }
                  className="pb-2 pt-3"
                />

                <Form.Check
                  type="radio"
                  id="tipo_cliente_destinatario"
                  label="Destinatario"
                  name="tipo_cliente"
                  ref={register}
                  value="destinatario"
                  onChange={(event) =>
                    changeTypeCliente(event, setValue, getValues)
                  }
                />
              </div>
            </Form.Group>
          </Col>
        </Form.Row>
      </PanelDetalle>
      <Row className="mb-1 mt-1">
        <Remitente
          formElement={formElement}
          companyDataBasic={companyDataBasic}
          actions={actions}
          state={state}
          ordenInfo={dataTransportOrder}
          validarRut={validarRut}
          setHidden={setHidden}
          popover={popover}
          control={control}
          editOrden={editOrden}
          getValues={getValues}
        />
      </Row>
      <ModalMessage handleClose={handleCloseModal} hidden={hidden} />
      {openCloseCta && (
        <ModalSelectNroCuenta
          handleOpenCloseCta={handleOpenCloseCta}
          openCloseCta={openCloseCta}
          cliente={customer}
          selectCodeClient={selectCodeClient}
        />
      )}
    </Fragment>
  );
};

export default Principal;
