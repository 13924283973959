import { useEffect } from "react";
import { Row, Form, Col, Button, Card, InputGroup } from "react-bootstrap";
import { useFormOffice } from "hooks/Admin/Offices/useFormOffice";
import {
  validateOnlyLetter,
  handlePasteOnlyLetter,
  validateOnlyNumber,
  onPaste,
} from "utils/validationInput";
import { Link } from "react-router-dom";

const Formulario = ({ toast, datos, cmasterListado, officeId, cityId }) => {
  const { actions, formElement, state } = useFormOffice(cityId);

  const { dataOffice, listParentOffice, companyDataBasic, isVisibleOffice } =
    state;

  const {
    getOfficeById,
    validatePhone,
    setIsVisibleOffice,
    setResetPhone,
    isGoogleMapsUrl,
  } = actions;

  const { isLoading, register, handleSubmit, errors, onSubmit, setValue } =
    formElement;

  useEffect(() => {
    getOfficeById(officeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  useEffect(() => {
    setValue("parent_office_id", dataOffice?.parent_office_id ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParentOffice, dataOffice?.parent_city_id]);

  useEffect(() => {
    if (companyDataBasic !== null && dataOffice !== null) {
      setResetPhone(dataOffice, companyDataBasic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOffice, companyDataBasic]);

  return (
    <Form id="frmOficina" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="parent_office_id">
                <Form.Label>Ciudad</Form.Label>
                <Form.Control
                  as="select"
                  name="parent_office_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  disabled={officeId !== null}
                >
                  <option value="">Seleccione</option>
                  {listParentOffice?.map((item) => (
                    <option value={item?.id}>{item?.name}</option>
                  ))}
                </Form.Control>
                {errors.parent_office_id && (
                  <Form.Text className="text-danger">
                    {errors?.parent_office_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => handlePasteOnlyLetter(event)}
                  maxLength={30}
                  className="text-uppercase"
                />
                {errors.name && (
                  <Form.Text className="text-danger">
                    {errors?.name?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Form.Group className="row-radio">
                <Form.Label>
                  Mostrar la oficina en el portal web y sitio web
                </Form.Label>
                <div className="ml-3">
                  <Form.Check
                    inline
                    label="Si"
                    name="activeOffice"
                    type="radio"
                    id={`inline-si`}
                    checked={isVisibleOffice === "si" ? true : false}
                    onChange={() => setIsVisibleOffice("si")}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="activeOffice"
                    type="radio"
                    id={`inline-no`}
                    checked={isVisibleOffice === "no" ? true : false}
                    onChange={() => setIsVisibleOffice("no")}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                    //eslint-disable-next-line
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                      message: "Email inválido",
                    },
                  })}
                  maxLength={100}
                  className="text-lowercase"
                />
                {errors.email && (
                  <Form.Text className="text-danger">
                    {errors?.email?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="telephone_numbers">
                <Form.Label>Teléfono</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {companyDataBasic?.country?.code}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name="telephone_numbers"
                    //defaultValue={datos?.phone_legal_agent || ""}
                    maxLength={15}
                    ref={register({
                      minLength: { value: 9, message: "Mínimo 9 dígitos" },
                      required: { value: true, message: "Requerido" },
                      //eslint-disable-next-line
                      pattern: {
                        value: /^([0-9]+)$/,
                        message: "Teléfono inválido",
                      },
                      validate: validatePhone,
                    })}
                    autoComplete="off"
                    onKeyPress={(event) => validateOnlyNumber(event)}
                    onPaste={(event) => onPaste(event)}
                  />
                </InputGroup>
              </Form.Group>
              {errors.telephone_numbers && (
                <Form.Text className="text-danger">
                  {errors?.telephone_numbers?.message}
                </Form.Text>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="address">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                  maxLength={100}
                  className="text-uppercase"
                />
                {errors.address && (
                  <Form.Text className="text-danger">
                    {errors?.address?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="url_map">
                <Form.Label>
                  Mapa (URL de la ubicación de google maps)
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="url_map"
                  rows={6}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                    validate: (value) =>
                      isGoogleMapsUrl(value) ||
                      "La URL debe ser de Google Maps",
                  })}
                />
                {errors.url_map && (
                  <Form.Text className="text-danger">
                    {errors?.url_map?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="description">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  rows={2}
                  ref={register({
                    //required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                />
                {errors.description && (
                  <Form.Text className="text-danger">
                    {errors?.description?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="schedule">
                <Form.Label>Horario</Form.Label>
                <Form.Control
                  as="textarea"
                  name="schedule"
                  rows={2}
                  ref={register}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to={`/admin/cities/${cityId}/offices`}>
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading
                ? "Procesando..."
                : officeId !== null
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;
